exports.components = {
  "component---src-pages-24-h-2006-js": () => import("./../../../src/pages/24h/2006.js" /* webpackChunkName: "component---src-pages-24-h-2006-js" */),
  "component---src-pages-24-h-2007-js": () => import("./../../../src/pages/24h/2007.js" /* webpackChunkName: "component---src-pages-24-h-2007-js" */),
  "component---src-pages-24-h-2008-js": () => import("./../../../src/pages/24h/2008.js" /* webpackChunkName: "component---src-pages-24-h-2008-js" */),
  "component---src-pages-24-h-2009-js": () => import("./../../../src/pages/24h/2009.js" /* webpackChunkName: "component---src-pages-24-h-2009-js" */),
  "component---src-pages-24-h-2010-js": () => import("./../../../src/pages/24h/2010.js" /* webpackChunkName: "component---src-pages-24-h-2010-js" */),
  "component---src-pages-24-h-2011-js": () => import("./../../../src/pages/24h/2011.js" /* webpackChunkName: "component---src-pages-24-h-2011-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ajopaikat-index-js": () => import("./../../../src/pages/ajopaikat/index.js" /* webpackChunkName: "component---src-pages-ajopaikat-index-js" */),
  "component---src-pages-oktoberfest-2006-js": () => import("./../../../src/pages/oktoberfest/2006.js" /* webpackChunkName: "component---src-pages-oktoberfest-2006-js" */),
  "component---src-pages-oktoberfest-2007-js": () => import("./../../../src/pages/oktoberfest/2007.js" /* webpackChunkName: "component---src-pages-oktoberfest-2007-js" */),
  "component---src-pages-oktoberfest-2009-js": () => import("./../../../src/pages/oktoberfest/2009.js" /* webpackChunkName: "component---src-pages-oktoberfest-2009-js" */),
  "component---src-pages-oktoberfest-2012-js": () => import("./../../../src/pages/oktoberfest/2012.js" /* webpackChunkName: "component---src-pages-oktoberfest-2012-js" */),
  "component---src-pages-oktoberfest-2013-js": () => import("./../../../src/pages/oktoberfest/2013.js" /* webpackChunkName: "component---src-pages-oktoberfest-2013-js" */),
  "component---src-pages-uutiset-js": () => import("./../../../src/pages/uutiset.js" /* webpackChunkName: "component---src-pages-uutiset-js" */),
  "component---src-pages-viikkoajot-2007-js": () => import("./../../../src/pages/viikkoajot/2007.js" /* webpackChunkName: "component---src-pages-viikkoajot-2007-js" */),
  "component---src-templates-front-page-template-js": () => import("./../../../src/templates/frontPageTemplate.js" /* webpackChunkName: "component---src-templates-front-page-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

